import React from 'react'
import { Link, graphql } from 'gatsby'
import { PageLayout, CartAddSKUButton } from '../components'
import { productURL } from '../lib/product'
import { t } from '../lib/locale'

const InteriorAdviceNL = ({ items, lang }) => {
  return (
    <div className="product-list-4 product-list-breadcrumbs mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-3">Interieuradvies</h2>
            <p>Persoonlijk advies & een échte klantenservice, dat vinden wij het allerbelangrijkst. Dus heb je een interieurvraagstuk? Onze deur staat altijd voor je open. Kom zeker langs tijdens onze openingsuren, en we helpen je met veel plezier en enthousiasme verder! Breng zeker ook foto’s en wat afmetingen mee; dan kunnen we gerichter helpen en tips geven.</p>
            <p>Heb je nog geen idee hoe je jouw ruimte moet inrichten? Of heb je nood aan één-op-één begeleiding? Dan zijn onze interieurpakketten misschien wel iets voor jou. </p>
          </div>
        </div>
      </div>

      {items && items.length > 0 ? (
        <div className="product-list">
          <div className="container">
            <div className="row mb-5">

              <div className="col-12">
                <div className="row max-height">
                  <div className="col-6 max-height pb-5">
                    <Link to={productURL(items[1], lang)}>
                      <img alt={t(items[1], 'name', lang)} src={`${process.env.GATSBY_CDN_URL}/p/${items[1].id_default_image.toString().split('').join('/')}/${items[1].id_default_image}.jpg`} className="img-fluid mb-4" />
                    </Link>
                  </div>
                  <div className="col-6 max-height pb-5">
                    <Link to={productURL(items[1], lang)}>
                      <h3>{t(items[1], 'name', lang)}</h3>
                    </Link>
                    <div dangerouslySetInnerHTML={{ __html: t(items[1], 'description_short', lang) }} />
                    <CartAddSKUButton sku={items[1]} lang={lang} className="btn-large-secondary mt-3 btn-advice" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      ) : ''}

      <div class="content-block margin-bottom">
        <div class="row content-block-wrapper">
          <p>Ben je <b>(interieur)architect</b> en zou je graag (regelmatig) met ons samenwerken? Neem gerust contact met ons op via <a href="mailto:hello@shopupnorth.com">hello@shopupnorth.com</a>.</p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 mb-3">Interieuradvies voor jouw bedrijf?</h2>
            <p>Wil je jouw kantoor, praktijkruimte, wachtzaal, showroom of jouw nieuwe restaurant inrichten? Wij helpen je graag!</p>
            <h3>Wat mag je van ons verwachten? </h3>
            <ul>
              <li>advies van één van onze interieurexperts + projectbegeleiding zodat je steeds op de hoogte blijft van de voortgang van het project</li>
              <li>een moodboard zodat de stijl doorgetrokken wordt in de volledige ruimte</li>
              <li>2D en/of 3D visualisatie afhankelijk van de noden van het project </li>
              <li>begeleiding in de keuzes van meubels & decoratie (met oog op verhuur en/of intensief gebruik)</li>
              <li>levering en/of installatie van de gekozen meubels  </li>
            </ul>
            <p>Stuur ons een mailtje naar <a href="mailto:hello@shopupnorth.com">hello@shopupnorth.com</a> met een korte samenvatting van het project, en we nemen zo snel mogelijk contact op.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const InteriorAdviceEN = ({ items, lang }) => {
  return (
    <div className="product-list-4 product-list-breadcrumbs mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-3">Interior Decoration Advice</h2>
            <p>Personalized advice & real customer service, that's what we strive for above anything. So if you are having an interior decoration dilemma? Then our door is alwaus open. Be sure to swing by during our opening hours and we will gladly help you! And if you can bring some pictures and sketches, we'll be able to help you even better.</p>
            <p>You don't have a clue yet how to decorate your home? Or do you need a step-by-step personalized plan? Then our interior packs may be just the thing you are looking for.</p>
          </div>
        </div>
      </div>

      {items && items.length === 2 ? (
        <div className="product-list">
          <div className="container">
            <div className="row mb-5">
              <div className="col-6">

                <div className="row max-height">
                  <div className="col-6 max-height pb-5">
                    <Link to={productURL(items[1], lang)}>
                      <img alt={t(items[1], 'name', lang)} src={`${process.env.GATSBY_CDN_URL}/p/${items[1].id_default_image.toString().split('').join('/')}/${items[1].id_default_image}.jpg`} className="img-fluid mb-4" />
                    </Link>
                  </div>
                  <div className="col-6 max-height pb-5">
                    <Link to={productURL(items[1], lang)}>
                      <h3>{t(items[1], 'name', lang)}</h3>
                    </Link>
                    <div dangerouslySetInnerHTML={{ __html: t(items[1], 'description_short', lang) }} />
                    <CartAddSKUButton sku={items[1]} lang={lang} className="btn-large-secondary mt-3 btn-advice" />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      ) : ''}

      <div class="content-block margin-bottom">
        <div class="row content-block-wrapper">
          <p>Are you an <b>(interior) architect</b> and you would like to (frequently) collaborate with us? Don't hesitate to contact us via <a href="mailto:hello@shopupnorth.com">hello@shopupnorth.com</a>.</p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 mb-3">Interior decoration advice for your company?</h2>
            <p>Are you looking to redecorate your office, practice, waiting room, showroom or new restaurant? We would love to help you!</p>
            <h3>What can you expect?</h3>
            <ul>
              <li>advice by one of our interior expects + project management to keep you up to date on progress within the project</li>
              <li>a moodboard so you can apply the style choices in all spaces</li>
              <li>2D and/or 3D visualisation depending on the needs of the project </li>
              <li>coaching and support in your choices for the furtniture & decoration (given the context of what you are trying to achieve)</li>
              <li>delivery and/or installation of the chosen furniture</li>
            </ul>
            <p>Send us an email via <a href="mailto:hello@shopupnorth.com">hello@shopupnorth.com</a> with a short summary of the project, and we will get back to you straight away.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const InteriorAdviceTemplate = ({ data, pageContext }) => {
  const { lang, page } = pageContext

  const items = data.products_interior_advice.edges.map(({ node }) => node)

  return (
    <PageLayout lang={lang} switcher={page} fullwidth={true}>
      {lang.id_lang === 1 && (<InteriorAdviceEN items={items} lang={lang} />)}
      {lang.id_lang === 4 && (<InteriorAdviceNL items={items} lang={lang} />)}
    </PageLayout>
  )
}

export default InteriorAdviceTemplate

export const query = graphql`
  query InteriorAdviceTemplateQuery($products_interior_advice: [Int]) {
    products_interior_advice: allShopProduct(filter: {id_product: {in: $products_interior_advice}}) {
      edges {
        node {
          ...ProductItem
        }
      }
    }
  }
`
